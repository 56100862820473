import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import api from '../../services/api';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

import { Container, Controls, Button, Error } from './styles';

const headers = {
  Authorization: `token 8c14b363175820fb9e18d438e02104a0950c912a`,
  Accept: 'application/vnd.github.v3+json',
};

const addCard = async issueId => {
  const newApi = axios.create();

  await newApi.post(
    'https://api.github.com/projects/columns/12082737/cards',
    {
      content_id: issueId,
      content_type: 'Issue',
    },
    {
      headers: {
        Accept: 'application/vnd.github.inertia-preview+json',
        Authorization: `token 8c14b363175820fb9e18d438e02104a0950c912a`,
      },
    },
  );
};

function NewIssue({ history }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [title, setTitle] = useState('');
  const [user, setUser] = useState('');
  const [body, setBody] = useState('');
  const [labels, setLabels] = useState([]);

  async function createIssue() {
    if (!title || !body || !labels.length || !user) {
      setError('Please make sure all fields are filled in correctly.');
      return;
    }

    setLoading(true);

    try {
      const { data: newIssue } = await api.post(
        '/Native.Frontend/issues',
        {
          title,
          body: `${body}  \n  \n  Criada por: **${user}**`,
          labels,
        },
        { headers },
      );

      await addCard(newIssue.id);
    } catch (err) {
      setError('Error creating new issue, try again!');
      setLoading(false);
    }

    setTitle('');
    setBody('');
    setLabels([]);
    setLoading(false);
  }

  return (
    <>
      <Header />

      <Container>
        {loading ? (
          <Loading text="SAVING ISSUE..." />
        ) : (
          <>
            <Controls>
              {error && <Error>{error}</Error>}

              <h3>Create new issue</h3>
              <div>
                <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Title"
                  onChange={event => setTitle(event.target.value)}
                />

                <input
                  type="text"
                  name="user"
                  id="user"
                  placeholder="User"
                  onChange={event => setUser(event.target.value)}
                />

                <select
                  name="filter"
                  id="filter"
                  onChange={event => setLabels([event.target.value])}
                >
                  <option value="">Select issue type</option>
                  <option value="bug">Bug</option>
                  <option value="enhancement">Enhancement</option>
                </select>
              </div>

              <textarea
                name="body"
                id="body"
                rows="20"
                placeholder="Leave a comment"
                onChange={event => setBody(event.target.value)}
              />

              <div>
                <Button
                  type="button"
                  value="Cancel"
                  bgColor="#d73a4a"
                  onClick={() => history.goBack()}
                />

                <Button
                  type="button"
                  value="Submit new issue"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={createIssue}
                />
              </div>
            </Controls>
          </>
        )}
      </Container>
    </>
  );
}

NewIssue.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
};

NewIssue.defaultProps = {
  history: {},
};

export default NewIssue;
