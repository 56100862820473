import styled from 'styled-components';

const types = {
  bug: '#fff',
  enhancement: '#000',
};

export const Container = styled.div`
  max-width: 960px;
  margin: 80px auto;
`;

export const Controls = styled.div`
  margin: 20px 20px 0 20px;
  display: flex;

  input {
    font-size: 14px;
    line-height: 20px;
    border-radius: 5px;
  }

  input[type='button'] {
    width: 100px;
    padding: 5px 16px;
    border: 1px solid #2ea44f;
    background-color: #2ea44f;
    color: #fff;
    appearance: none;
    cursor: pointer;

    transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    transition-property: color, background-color, border-color;

    &:hover {
      background-color: #2c974b;
      border-color: rgba(27, 31, 35, 0.15);
    }
  }

  input[type='text'] {
    width: 100%;
    margin-right: 10px;
    color: #586069;
    background-color: #fafbfc;
    padding: 5px 12px;
    border: 1px solid #e1e4e8;
    border-radius: 0 5px 5px 0;
  }

  select {
    background-color: #fafbfc;
    padding: 5px 12px;
    border: 1px solid #e1e4e8;
    border-radius: 5px 0 0 5px;
    border-right: none;
    cursor: pointer;
    appearance: none;
    width: 180px;

    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 3px;

    &:last-of-type {
      border-radius: 0;
    }

    &:first-of-type {
      width: 140px;
    }

    &:hover {
      background-color: #f3f4f6;
      border-color: rgba(27, 31, 35, 0.15);
      transition-duration: 0.1s;
    }
  }
`;

export const IssuesList = styled.div`
  margin: 10px 20px;

  border: 1px solid #eaecef;
  border-radius: 5px;
`;

export const Issue = styled.div`
  border-bottom: 1px solid #eaecef;
  background-color: #f6f8fa;
  padding: 10px;
  cursor: pointer;
  transition: opacity 0.2s;

  &:last-child {
    border: none;
  }

  &:nth-child(2n) {
    background-color: #fff;
  }

  div {
    display: flex;
    align-items: center;
  }

  &:hover {
    color: #1189fa;
  }

  svg {
    margin-right: 4px;
    padding-bottom: 4px;
  }

  img {
    border-style: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
`;

export const Label = styled.div`
  padding: 3px 8px;
  margin-left: 5px;
  border-radius: 10px;
  font-size: 12px;
  background-color: ${props => (props.bgColor ? `#${props.bgColor}` : '#fff')};
  color: ${props => (props.type ? `${types[props.type]}` : '#000')}; ;
`;

export const Info = styled.div`
  margin-left: 25px;
  font-size: 12px;
  color: #586069;
`;

export const IssueBody = styled.div`
  font-size: 14px;
  background-color: #fff;
  color: #333;
  border: ${props => (props.open ? '1px solid #eaecef' : 'none')};
  border-radius: 5px;
  max-height: ${props => (props.open ? '100%' : '0')};
  margin: ${props => (props.open ? '20px 20px 10px 20px' : '0 20px')};
  padding: ${props => (props.open ? '10px 15px' : '0 15px')};
  overflow: hidden;
  transition: all 0.2s ease-out;
  white-space: pre-wrap;

  li {
    list-style: none;
    margin: 5px 0;

    & > input {
      margin-right: 5px;
    }
  }
`;

export const ResultLabel = styled.div`
  margin: 5px 20px;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  color: #29292e;
`;
