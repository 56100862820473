import styled from 'styled-components';

export const Container = styled.div`
  max-width: 780px;
  margin: 80px auto;
  border: 1px solid #eaecef;
  border-radius: 5px;
`;

export const Controls = styled.div`
  margin: 20px 20px 0 20px;

  div {
    display: flex;
    justify-content: flex-end;
  }

  h3 {
    margin-bottom: 5px;
  }

  input {
    font-size: 14px;
    line-height: 20px;
    border-radius: 3px;
  }

  input[type='text'] {
    width: 100%;
    margin-right: 10px;
    color: #586069;
    background-color: #fafbfc;
    padding: 5px 12px;
    border: 1px solid #e1e4e8;
  }

  select {
    background-color: #fafbfc;
    padding: 5px 12px;
    border: 1px solid #e1e4e8;
    border-radius: 5px;
    cursor: pointer;
    appearance: none;
    width: 500px;

    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 3px;

    &:hover {
      background-color: #f3f4f6;
      border-color: rgba(27, 31, 35, 0.15);
      transition-duration: 0.1s;
    }
  }

  textarea {
    width: 100%;
    margin: 10px 10px 0 0;
    color: #586069;
    background-color: #fafbfc;
    padding: 5px 12px;
    border: 1px solid #e1e4e8;
    border-radius: 5px;
    resize: none;
  }
`;

export const Button = styled.input`
  padding: 5px 16px;
  border: 1px solid ${props => (props.bgColor ? props.bgColor : '#2ea44f')};
  background-color: ${props => (props.bgColor ? props.bgColor : '#2ea44f')};

  color: #fff;
  appearance: none;
  cursor: pointer;
  margin: 10px 0 20px 0;

  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;

  &:hover {
    background-color: ${props => (props.bgColor ? '#bf3241' : '#2c974b')};
    border-color: rgba(27, 31, 35, 0.15);
  }

  &:last-child {
    margin-left: 5px;
  }
`;

export const Error = styled.div`
  justify-content: flex-start !important;
  padding: 10px;
  margin: 20px 0 10px 0;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  border-radius: 5px;
`;
