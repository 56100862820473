import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin: 0;
  top: 0;

  background-color: #24292e;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.5;
  height: 3.5rem;

  div {
    display: flex;
    align-items: center;
  }
`;
