import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Issues from '../pages/Issues';
import NewIssue from '../pages/NewIssue';

function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Issues} />
      <Route path="/new" exact component={NewIssue} />
    </Switch>
  );
}

export default Routes;
