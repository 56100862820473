import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { RiErrorWarningLine, RiCheckboxCircleLine } from 'react-icons/ri';

import api from '../../services/api';
import useDebounce from '../../hooks/useDebounce';
import relativeTime from '../../utils/relativeTime';

import Header from '../../components/Header';
import Loading from '../../components/Loading';

import {
  Container,
  Controls,
  IssuesList,
  Issue,
  IssueBody,
  Label,
  Info,
  ResultLabel,
} from './styles';

const headers = {
  Authorization: `token 8c14b363175820fb9e18d438e02104a0950c912a`,
  Accept: 'application/vnd.github.v3+json',
};

function Issues({ history }) {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [filterType, setFilterType] = useState('');
  const [stateType, setStateType] = useState('open');
  const debouncedFilterType = useDebounce(filterType, 100);
  const debouncedStateType = useDebounce(stateType, 100);
  const [loading, setLoading] = useState(true);
  const [issues, setIssues] = useState([]);
  const [filteredIssues, setFilteredIssues] = useState([]);

  async function requestIssues(labels) {
    const state = stateType || 'all';
    setLoading(true);
    const { data: newIssues } = await api.get('/Native.Frontend/issues', {
      params: { state, per_page: 100, labels },
      headers,
    });

    return newIssues;
  }

  async function getIssues() {
    let newIssues = [];
    if (!filterType.trim()) {
      const bugs = await requestIssues('bug');
      const enhancements = await requestIssues('enhancement');

      const ids = new Set(bugs.map(d => d.id));
      newIssues = [...bugs, ...enhancements.filter(d => !ids.has(d.id))];

      newIssues.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.number < b.number ? 1 : b.number < a.number ? -1 : 0,
      );
    } else {
      newIssues = await requestIssues(filterType);
    }

    setIssues(newIssues);
    setLoading(false);
  }

  useEffect(() => {
    getIssues();
  }, [stateType, filterType]);

  useEffect(() => {
    const newIssues = issues.filter(issue => {
      if (debouncedSearchTerm.trim()) {
        const includeOnTitle = issue.title
          .toLowerCase()
          .includes(debouncedSearchTerm.toLowerCase());

        let includeOnBody = false;
        if (issue.body) {
          includeOnBody = issue.body
            .toLowerCase()
            .includes(debouncedSearchTerm.toLowerCase());
        }

        return includeOnTitle || includeOnBody;
      }

      return true;
    });

    setFilteredIssues(newIssues);
  }, [debouncedSearchTerm, issues]);

  const handleOpen = issueId => {
    const newIssues = [...issues];

    newIssues.map(issue => {
      if (issue.id === issueId) {
        issue.isOpen = !issue.isOpen;
      }
    });

    setIssues(newIssues);
  };

  const filterTypeOptions = [
    { value: '', label: 'All types' },
    { value: 'bugs', label: 'Bugs' },
    { value: 'enhancement', label: 'Enhancements' },
  ];

  return (
    <>
      <Header />
      <Container>
        {loading ? (
          <Loading text="LOADING ISSUES..." />
        ) : (
          <>
            <Controls>
              <select
                name="state"
                id="state"
                onChange={event => setStateType(event.target.value)}
                value={stateType}
              >
                <option value="">All states</option>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
              </select>

              <select
                name="filter"
                id="filter"
                onChange={event => setFilterType(event.target.value)}
                value={filterType}
              >
                <option value="">All types</option>
                <option value="bug">Bugs</option>
                <option value="enhancement">Enhancement</option>
              </select>

              <input
                type="text"
                name="Search"
                id="search"
                placeholder="Digite para pesquisar"
                onChange={event => setSearchTerm(event.target.value)}
              />
              <input
                type="button"
                value="New issue"
                onClick={() => history.push('/new')}
              />
            </Controls>

            <ResultLabel>{`${issues.length} registros carregados`}</ResultLabel>

            <IssuesList>
              {(debouncedSearchTerm || debouncedFilterType || debouncedStateType
                ? filteredIssues
                : issues
              ).map(issue => (
                <Issue key={issue.id} onClick={() => handleOpen(issue.id)}>
                  <div>
                    {issue.state === 'open' ? (
                      <RiErrorWarningLine
                        size={22}
                        color="#22863a"
                        title="Open"
                      />
                    ) : (
                      <RiCheckboxCircleLine
                        size={22}
                        color="#d73a4a"
                        title="Closed"
                      />
                    )}

                    <h4>{issue.title}</h4>
                    {issue.labels.map(label => (
                      <Label
                        key={label.id}
                        bgColor={label.color}
                        type={label.name}
                      >
                        {label.name}
                      </Label>
                    ))}

                    {issue.assignee && (
                      <img src={issue.assignee.avatar_url} alt="user avatar" />
                    )}
                  </div>

                  <Info>
                    {`#${issue.number} aberta ${relativeTime(
                      new Date(issue.created_at),
                    )} por ${issue.user.login} `}
                  </Info>

                  {issue.state !== 'open' && (
                    <Info>
                      {`Encerrada ${relativeTime(
                        new Date(issue.closed_at),
                      )} por ${issue.user.login} `}
                    </Info>
                  )}

                  <IssueBody open={issue.isOpen}>
                    <ReactMarkdown plugins={[gfm]}>
                      {issue.body
                        ? issue.body.replace(/\n/gi, '\n &nbsp;')
                        : 'No description provided'}
                    </ReactMarkdown>
                  </IssueBody>
                </Issue>
              ))}
            </IssuesList>
          </>
        )}
      </Container>
    </>
  );
}

Issues.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

Issues.defaultProps = {
  history: {},
};

export default Issues;
