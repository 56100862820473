const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

const relativeTime = date => {
  const rtf = new Intl.RelativeTimeFormat('pt-BR', { numeric: 'auto' });
  const elapsed = date - new Date();
  let relative = null;

  Object.keys(units).forEach(unit => {
    if (!relative && (Math.abs(elapsed) > units[unit] || unit === 'second')) {
      relative = rtf.format(Math.round(elapsed / units[unit]), unit);
    }
  });

  return relative;
};

export default relativeTime;
