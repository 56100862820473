import React from 'react';

import { Container } from './styles';
import Github from '../../assets/github.png';

function Header() {
  return (
    <Container>
      <img src={Github} alt="Github logo" height={48} />
      Native Issues
    </Container>
  );
}

export default Header;
