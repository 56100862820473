import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';

import { Container } from './styles';

function Loading({ text }) {
  return (
    <Container>
      <p>{text}</p>
      <ReactLoading type="bars" color="#bbb" height={64} width={64} />
    </Container>
  );
}

Loading.propTypes = {
  text: PropTypes.string,
};

Loading.defaultProps = {
  text: 'LOADING...',
};

export default Loading;
